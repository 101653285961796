<template>
  <div>
    <!--    get number dialog-->
    <v-dialog v-model="needToReceivedCell" width="500" persistent>
      <v-form ref="editPhone" lazy-validation>
        <v-card class="ga_signup ga_signup_new_preorder">
          <v-card-title>
            <p class="text-center">
              {{ $t('socialLogin.enterNumber') }}
            </p>
          </v-card-title>
          <v-card-text>
            <div class="ltrInput">
              <v-text-field
                  class="mt-2"
                  v-model="tell"
                  outlined
                  append-icon="mdi-cellphone"
                  type="tel"
                  name="tel"
                  placeholder="example : +98 123 123 1234"
                  :rules="phoneRules"
                  style="direction: ltr"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="editProfile" :loading="loadingTell">
              {{ $t('confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!--    get number dialog-->
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import AuthMixins from "@/mixins/AuthMixins";
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [AuthMixins, EventsMixins],
  methods: {
    sendWebEngageRegisterEvent() {
      window.webengage.track('registered_on', {
        date: this.registered_on
      });
    },
    showSnackbar(color = 'success', timeout = 5000, message = this.$t('Notify.successfullyV2'), snackbarId = null) {
      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

      if (snackbarId != null) {

        this.$store.commit('SET_SNACKBAR_ID', snackbarId)

      }
    },
    editProfile() {
      if (this.$refs.editPhone.validate()) {

        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'editProfile', '', {
          mobile: this.tell
        }).then((resp) => {

          this.WebengageSetUserRequiredFiled(resp.data.profile)

          this.$emitEvent('notify', {
            color: 'green',
            title: 'شماره شما ثبت شد',
            id: this.GALogin
          });
          this.$router.push({
            name: 'preorder', params: {
              lang: "fa",
              orderId: 68
            }
          });
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        }).catch(() => {
          this.$emitEvent('notify', {
            color: 'red',
            title: this.$t('notify.failed'),
            id: this.GALogin
          });
          // this.$emitEvent('notify', 'green', 3000, this.$t('notify.failed'), this.GALogin);
        })
      }
    },

  },
  data() {
    return {
      registered_on: new Date(),
      loading: true,
      needToReceivedCell: false,
      tell: null,
      loadingTell: false,
      temp: '',
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
    }
  },
  computed: {},
  watch: {
    tell() {
      if (this.tell.startsWith('00')) {
        this.tell = this.tell.substr(2);
        this.tell = '+' + this.tell;
      }

      if (this.tell.startsWith('098')) {
        this.tell = this.tell.substr(1);
        this.tell = '+' + this.tell;
      }

      if (this.tell.startsWith('09')) {
        this.tell = this.tell.substr(1);
        this.tell = '+98' + this.tell;
      }

      this.tell = new AsYouType().input(this.tell);

    },
  },
  mounted() {
    EventBus.$on('notify', this.showSnackbar);

    this.attachTelegramAccount()

    this.$store.commit('setMinHeight');
  },
  created() {
    if (this.$route.query.token != undefined) {
      this.$store.commit('setToken', {token: 'Bearer ' + this.$route.query.token});
      this.requesting('auth', 'getProfile').then((resp) => {
        this.WebengageSetUserRequiredFiled(resp.data.profile)
        if (this.$route.query.registered != undefined) {
          this.sendWebEngageRegisterEvent();
        }
        this.$store.commit('SET_LOGIN');
        this.$emitEvent('userLoggedIn');
        let surveyData = localStorage.getItem('surveyData');
        this.tell = resp.data.profile.cel
        // console.log(resp.dat/a.profile.cel != null ? 'not null' : 'null')
        if (resp.data.profile.cel != null) {
          if (surveyData != null && surveyData != undefined) {
            this.requesting('user', 'SendSurveyAnswer', '', surveyData).then(() => {
              this.$router.push({
                name: 'preorder', params: {
                  lang: "fa",
                  orderId: 68
                }
              });
            });
          } else {
            this.$router.push({
              name: 'preorder', params: {
                lang: "fa",
                orderId: 68
              }
            });
          }
        } else {
          this.needToReceivedCell = true
        }
      }).catch((er) => {
        if (er.response.status == 401) {
          this.$store.dispatch('logout')
          this.$router.push('/fa/login')
        }
      })
    } else {
    }
    return;
  },
}
</script>


<style scoped>

</style>